
.main_sec {
  padding: 40px 0 40px;
}

.tabContent {
  padding-top: 20px;
}

.button {
  background: none;
  outline: none;
  border: none;
  text-decoration: underline;
  text-underline-offset: 2px;
}