
.checkout-sec {
  background: rgba(118, 109, 244, .1);
  min-height: 100vh;
  height: auto;
  padding-bottom: 30px;
}

.checkout-header {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  padding: 15px 25px;
  background: #fff;
  margin-bottom: 20px;
}

.content-sec {
  background: #fff;
  padding: 10px 25px 30px;
  border-radius: 8px;
}