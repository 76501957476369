.mainSec {
  margin-top: 150px;
  max-width: 1100px;
  padding: 0 15px;
  margin-bottom: 70px;
}

.mainSec button {
  padding: 7px 20px;
}

.tabContainer {
  background-color: #eef0f8;
  height: 100%;
  border-radius: 8px;
  padding: 20px 15px;
}

.tab {
  width: 100%;
  display: block;
  border: none;
  text-align: left;
  padding: 10px 15px !important;
  margin-bottom: 10px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
}

.tab:hover,
.activeTab {
  background: #766df4;
  color: #fff;
}

