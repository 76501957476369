.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid transparent !important; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}