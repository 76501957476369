.profile-save-button {
  position: absolute;
  top: 39%;
  padding: 5px 20px;
}

.profile-save-button.mob {
  display: none;
}

.ellipsis--2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*No of lines after which the ellipsis needs to be added*/
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
}

.count.unread {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: -3px;
  margin-right: -5px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  background-color: #f7cc53;
  border-radius: 40px;
  color: #fff;
  border: 2px solid #fff;
}

.notification-card {
  border-radius: 10px;
  max-width: 600px;
  margin: auto;
  padding: 10px !important;
  box-shadow: 0 0 10px #f1f1f1;
}

.my-bell-icon {
  font-size: 45px;
  opacity: 0.5;
}

.notification-card .notification-wrapper {
  max-height: 70vh;
  min-height: 400px;
}


.filler-job-form.search-input i {
  position: absolute !important;
  left: 20px;
}

.search-input i {
  z-index: 100;
}

.search-input-box .search {
  border: 1px solid #dbdfe2;
  border-radius: 6px;
  min-height: auto;
}

.search-input-box input {
  /* padding-right: 15px !important; */
  font-size: 14px !important;
}

.search-input-box input:placeholder-shown {
  text-overflow: ellipsis;
}

.search-input-box i {
  z-index: 100;
}

.range-slider__thumb,
.range-slider__range {
  background: var(--bs-purple) !important;
}

select {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: no-wrap;
  padding-right: 40px !important;
}

.search {
  background-color: #fff;
  border-radius: 8px 0px 0px 8px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search .wrapper {
  box-shadow: none !important;
  font-size: 15px !important;
}

.search .wrapper:first-child {
  border-radius: 8px;
  border: none;
  z-index: 10;
}

.search .wrapper>div>svg {
  visibility: hidden;
}

.search .sc-gTRrQi {
  box-shadow: 0px 18px 18px 1px rgba(32, 33, 36, 0.1) !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.filter-border::before {
  z-index: 100;
}

.trusted-comp {
  max-height: 40px;
}

.home-carousel {
  margin-top: 110px;
}

.home-carousel img {
  width: 100%;
}

.home-carousel .swiper-wrapper {
  max-height: 380px;
}

.home-carousel .swiper-slide {
  text-align: center;
}

.search-sec {
  max-width: 1000px;
  padding: 0 15px;
  margin: auto;
}

.filter-search-row {
  min-height: 60px;
}

.trending-keyword {
  color: #000 !important;
}

.search-sec .search-job-form {
  box-shadow: 0 0 15px rgb(0, 0, 0, 0.1);
  border-radius: 10px;
}

.client-swiper-wrapper {
  margin-top: 30px;
  max-height: 200px;
  overflow: hidden;
}

.client-swiper-wrapper .client-comp-img {
  max-height: 100px;
}

.client-swiper-wrapper.trusted-company {
  max-height: 80px !important;
}

.my-link-btn {
  text-decoration: underline;
}

.my-link-btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.link {
  cursor: pointer !important;
}

.autocomplete-select .select__control {
  border: none;
  box-shadow: none !important;
  outline: none;
  width: 100%;
  height: 42px;
}

.select__clear-indicator {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.autocomplete-select .select__menu {
  font-size: 14px;
}

.select__dropdown-indicator {
  padding-left: 2px !important;
}

.autocomplete-select .select__indicator-separator {
  display: none;
}

.autocomplete-select input {
  min-width: 80px !important;
}

.autocomplete-select .select__placeholder {
  font-size: 13px;
  white-space: nowrap;
}

.autocomplete-select .select__control input:focus {
  border: none;
  outline: none;
}

.autocomplete-select .select__value-container {
  padding-left: 40px !important;
}

.form-progress-sec {
  position: sticky;
  top: 65px;
  z-index: 100;
}

.form-progress-sec .progress {
  height: 15px;
}

.creditPanel {
  border: 1px solid #d0d0d0;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.active-badge {
  background: green;
  width: 10px;
  height: 10px;
  border-radius: 12px;
  right: 10px;
  font-size: 11px;
  display: inline-block;
  margin-left: 10px;
  transform: translateY(-6px);
  cursor: pointer;
}

.active-badge.inactive {
  background: #cbcbcb;
}

.google-play-icon {
  max-width: 170px;
}

.otp-input {
  width: 50px !important;
}

.otp-input::-webkit-inner-spin-button {
  display: none;
}

.filter-pill-bar {
  display: flex;
  gap: 8px;
  flex-wrap: wrap
}

.filter-pill {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 3px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px
}

.filter-pill span {
    font-size: 13px;
    color: #666666
}

.switch-btn {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 8px 20px
}

.switch-btn i {
  font-size: 20px;
}

.candidate-list-images {
  min-width: 4rem
}

.resume-modal {
  max-width: 820px !important;
  width: 100%;
}

.resume-uploader {
  border: 1px dashed #cbcbcb;
  border-radius: 8px;
  padding: 35px 15px;
  cursor: pointer;
  text-align: center;
}

.resume-uploader h6 span {
  color: var(--bs-purple);
}

.resume-uploader p {
  opacity: 0.9;
}

.resume-builder-section {
  padding: 20px 15px;
  padding-right: 30px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  gap: 25px;
  align-items: center;
}

.resume-builder-section button {
  min-width: 120px;
  padding: 8px 8px;
}

.resume-builder-section .dummy-resume {
  max-width: 100px;
  max-height: 110px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #eee;
}

.resume-builder-section .dummy-resume img {
  max-width: 100px;
  object-fit: cover;
}

.multi-selector {
  border: 1px solid #dbdfe2;
  border-radius: 0.25rem;
  margin-bottom: 15px;
}

.multi-selector input {
  border: none;
}

.pill-container {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 10px 10px 4px;
  flex-wrap: wrap;
  row-gap: 8px;
  max-height: 200px;
  overflow: auto;
}

.pill-btn {
  color: #606060;
  border: 1px solid #939393;
  background-color: #f4f4f4;
  border-radius: 30px;
  padding: 3px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.pill-btn .icon {
  cursor: pointer;
}

.hover-text {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.hover-text:hover {
  color: #766df4 !important;
}

@media (max-width: 767px) {
  .home-carousel {
    margin-top: 65px;
  }
}

@media (max-width: 600px) {
  .notification-card .job-list-menu {
    flex-direction: row !important;
  }

  .profile-save-button {
    position: initial;
    display: none;
  }

  .profile-save-button.mob {
    display: block;
    margin: auto;
    margin-bottom: 30px;
  }

  .current_status {
    margin-top: -70px;
    font-size: 12px;
  }

  .home-carousel img {
    width: 100%;
    object-fit: cover;
  }

  .otp-input {
    width: 38px !important;
    padding: 8px;
  }
}