

#paymentGatewayListContainer{
    padding: 20px 5px 20px 0;
    width: 100%;
}
#paymentGatewayListContainer input[type=radio]{
    height: 20px;
    width: 20px;
}

.paymentGatewayRow{
    padding: 20px 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.paymentOptionName {
    margin-left: 12px;
    font-weight: 600;
}

.paymentGatewayRow label {
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
}

.paymentGatewayRow img {
    margin-bottom: 0;
}

.paymentLogo{
    height: 30px;
    margin-left: 3px;
}

.loading {
    padding: 25px;
}

.payNowButton, .loadingButton{
    min-width: 150px;
    padding: 0 15px;
    height: 42px;
    cursor: pointer;
    border: 1px solid #766df4;
    border-radius: 5px;
    width: 100%;
    display: block;
    color: #fff;
    background-color: #766df4;
}

.payNowButton:hover, .loadingButton:hover{
    /* border: 1px solid rgb(86, 86, 86); */
    opacity: 0.8;
}

.payNowButtonContainer{
    display: block;
}

.errorMsg{
    color: red;
    padding: 15px;
}