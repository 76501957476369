.trackerContainer {
  padding: 40px 30px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  margin-bottom: 25px;
}

.trackerContainer h1 {
  font-size: 80px;
  font-weight: 500;
  line-height: 1.1;
}

.trackerContainer h4 {
  font-size: 18px;
}

.leftBox {
  border-right: 1px solid #d1d1d1;
  margin-right: 30px;
  height: 100%;
}

.progress {
  background: #eee;
  height: 18px;
  border-radius: 15px;
  margin-bottom: 15px;
}

.hintText {
  font-size: 14px;
}

.smallHeading {
  font-size: 17px !important;
}

.button {
  font-size: 13px;
  border-radius: 20px;
  padding: 7px 15px;
}

@media (max-width: 1200px) {
  .leftBox {
    margin-right: 10px;
  }
}

@media (max-width: 991px) {
  .trackerContainer {
    padding: 30px 25px;
  }
  .leftBox {
    border: none;
    border-bottom: 1px solid #eff0f2;
    height: auto;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  .trackerContainer h1 {
    font-size: 50px;
  }
}
