.stickyWhatsapp {
  border-radius: 50%;
  bottom: 20px;
  right: 30px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
  position: fixed;
  transition: 0.3s ease-in-out;
  z-index: 1000;
}

.stickyWhatsapp img {
  width: 55px;
  height: 55px;
}

.stickyWhatsapp:hover {
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.4);
  transition: 0.3s ease-in-out;
}

@media (max-width: 576px) {
  .stickyWhatsapp {
    bottom: 20px;
    right: 20px;
  }
  .stickyWhatsapp img {
    width: 42px;
    height: 42px;
  }
}
