:root {
  --resume-primary-color: #3830a4;
  --resume-secondary-color: #333;
  --resume-background-color: #f5f5f5;
  --resume-sidebar-color: #f7f7f7;
  --resume-text-color: #333;
}

.main-resume-container {
  margin: 0;
  /* Remove external margin */
}

.resume-container {
  display: flex;
  max-width: 1200px;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1.6;
  color: var(--resume-text-color);
  width: 100%;
  padding: 0;
  /* Ensure no padding is added */
}

.resume-container .sidebar {
  width: 30%;
  background-color: var(--resume-sidebar-color);
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resume-container .main-content {
  width: 70%;
  padding: 40px;
}

.resume-container .profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  border: 3px solid var(--resume-primary-color);
}

.resume-container h1 {
  font-size: 1.4em;
  color: var(--resume-primary-color);
  margin-bottom: 3px;
  text-align: center;
}

.resume-container h2 {
  font-size: 1.35em;
  color: var(--resume-primary-color);
  border-bottom: 2px solid var(--resume-primary-color);
  padding-bottom: 10px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.resume-container .contact-info {
  width: 100%;
}

.resume-container .contact-info .contact-item {
  margin-bottom: 10px;
}

.resume-container .contact-info .contact-item strong {
  display: block;
  font-size: 14px;
  margin-bottom: 3px;
}

.resume-container .contact-info .contact-item span {
  display: block;
  overflow-wrap: break-word;
  line-height: 1.3;
}

.resume-container .contact-info,
.resume-container .skills {
  margin-top: 0px;
  width: 100%;
}

.resume-container .contact-info p {
  margin: 5px 0;
}

.resume-container .contact-info a {
  color: var(--resume-text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.resume-container .contact-info a:hover {
  color: var(--resume-primary-color);
}

.resume-container .skills ul {
  list-style-type: none;
  padding: 0;
}

.resume-container .skills li {
  padding: 3px 10px;
  margin: 0;
  border-radius: 5px;
  font-size: 0.9em;
}

.resume-container .job {
  margin-bottom: 20px;
}

.resume-container .job-title {
  font-weight: bold;
  color: var(--resume-secondary-color);
  margin-bottom: 5px;
}

.resume-container .job-date {
  font-style: italic;
  color: #777;
  margin-bottom: 10px;
}

.resume-footer {
  display: none;
  text-align: right;
  font-size: 14px;
  color: #666;
  margin-top: 20px;
  padding-top: 10px;
  margin-top: 100px;
}

.resume-footer img {
  height: 18px;
  margin-left: 1px;
  margin-top: 5px;
}


@media (max-width: 768px) {
  .resume-container {
    flex-direction: column;
  }

  .resume-container .sidebar,
  .resume-container .main-content {
    width: 100%;
  }
}

@page {
  margin: 0;
  /* Remove all margin for the entire page */
}

/* Print-specific styling */
@media print {

  * {
    box-shadow: none !important;
  }

  .main-resume-container {
    width: 100%;
    margin: 0 auto;
  }

  .resume-container {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    /* Remove padding for print */
    box-shadow: none;
  }

  .resume-container .sidebar {
    width: 35%;
    float: left;
    height: 100vh;
    padding: 40px 25px;
  }

  .resume-container .main-content {
    width: 65%;
    float: right;
    padding: 40px 25px;
    /* Adjust as needed for spacing */
  }

  .resume-container h2,
  .resume-container h3,
  .resume-container p,
  .resume-container ul {
    page-break-inside: avoid;
  }

  .resume-container .clearfix::after {
    content: "";
    display: table;
    clear: both;
  }

  .main-content {
    position: relative;
    padding-bottom: 0px;
    min-height: 100vh;
  }
  
  .resume-footer {
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    position: fixed;
    page-break-before: always;
  }


}