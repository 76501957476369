.mainWrapper {
  position: relative;
  width: 100%;
}

.inputWrapper {
  position: relative;
}

.leftIcon,
.rightIcon {
  position: absolute;
  top: 12px;
}

.leftIcon {
  left: 10px;
  top: 5px;
}

.rightIcon {
  right: 10px;
  transition: all 0.3s ease-in-out;
}

.rightIcon svg {
  fill: #656565;
}

.inputWrapper input {
  padding: 10px 14px;
  padding-right: 33px;
  font-size: 14px;
  border: 1px solid #dbdfe2;
  outline: none;
  border-radius: 0.25rem;
  width: 100%;
}

.inputWrapper .withIconInput {
  padding-left: 35px;
}

.dropdown {
  position: absolute;
  /* border: 1px solid #eee; */
  list-style: none;
  padding: 0;
  min-width: 200px;
  margin-top: 10px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;
  z-index: 100;
  max-height: 300px;
  overflow-y: auto;
  padding: 5px;
}

.dropdown li {
  padding: 7px 12px;
  font-size: 14px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #f9f9f9;
}

.dropdown .activeOption {
  background: #656565 !important;
  color: #fff;
}

.nothingFound, 
.nothingFound:hover
 {
  cursor: auto;
  background: none !important;
}